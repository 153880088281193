<template>
  <div class="contact-data fill-height">
    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">
        <nav-bar-page/>
        <v-container>
          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h1 class="text-center today-regular text-title">PREPÁRATE PARA UNA SELFIE</h1>
              </div>
            </v-col>
            <v-col cols="11" md="6" class="mt-0 py-0">
              <div class="my-0 pt-0 primary--text">
                <h3 class="primary--text text-center mb-0 today-light line-height-1">
                  Realiza una validación biométrica de tú cara para mayor seguridad en el proceso
                </h3>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <div class="mt-4 text-center">
                <web-cam
                    v-if="!isPhotoTaken"
                    ref="webcam"
                    :device-id="deviceId"
                    width="370"
                    height="278"
                    @started="onStarted"
                    @stopped="onStopped"
                    @error="onError"
                    @cameras="onCameras"
                    @camera-change="onCameraChange"
                    style="border-radius:20px; transform: scale(-1, 1)"
                />
                <div v-if="isPhotoTaken" class="text-center col-md-12">
                  <figure class="figure">
                    <img :src="selfieImage"
                         width="370"
                         height="278" class="img-responsive"
                         style="transform: scale(-1, 1); border-radius: 20px"/>
                  </figure>
                </div>

                <div v-if="!isPhotoTaken">
                  <div class="text-center mt-4">
                    <v-btn
                        style="min-width: 240px"
                        @click="onCapture"
                        color="primary"
                        class="px-12 text-capitalize vardi-button"
                        elevation="0"
                        rounded
                        large>
                      TOMAR FOTO
                    </v-btn>
                  </div>
                </div>
                <v-row v-if="isPhotoTaken" class="mt-4" justify="center">
                  <v-col cols="12" sm="5" md="3">
                    <v-btn
                        @click="restart"
                        :disabled="loading"
                        color="primary"
                        block
                        rounded
                        outlined
                        class=" text-capitalize vardi-button"
                        elevation="2"
                        style="border: none"
                        large>
                      REINTENTAR
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="5" md="3">
                    <v-btn
                        @click="goPolicies"
                        :loading="loading"
                        :disabled="loading"
                        color="primary"
                        block
                        rounded
                        class=" text-capitalize vardi-button"
                        elevation="0"
                        large>
                      CONTINUAR
                    </v-btn>
                  </v-col>
                </v-row>
                <v-img v-if="frontPreviewImage" :src="frontPreviewImage" height="160" class="mt-2" contain></v-img>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>
    <v-dialog
        v-model="dialog"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">
        <v-img max-height="50"
               class="my-4"
               contain
               src="@/assets/img/sinPoliza.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">
          LO SENTIMOS
        </h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">
          Nuestro sistema no resporta pólizas
          activas para este usuario. Comunícate
          con un asesor.
        </h3>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 mt-4 text-capitalize today-bold vardi-button"
              @click="dialog = false"
              elevation="0"
              rounded
              to="/"
          >
            ACEPTAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
// or
import {WebCam} from "vue-web-cam";

export default {
  name: 'TakeSelfie',
  components: {LeftSideImage, NavBarPage, WebCam},
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: '#',
      user: null,
      loading: false,
      selfieImage: null,
      snackbar: false,
      snackbarMessage: '',
      timeout: 3000,
      frontPreviewImage: null,
      selectedFileFront: null,

      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      dialog: false
    }
  },
  mounted() {
    if (this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    devices: function () {
      const [first, ...tail] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
    }
  },
  methods: {
    onCapture() {
      if (this.$refs.webcam) {
        this.selfieImage = this.$refs.webcam.capture();
      }
      this.isPhotoTaken = true
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
    },

    goPolicies: function () {
      this.loading = true;
      this.user = locals.getLocal('user');
      let arr = this.selfieImage.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const selfieFile = new File([u8arr], 'selfie.png', {
        type: mime
      });
      if (this.user.login) {
        const formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('face_model', selfieFile);

        api.postFile(constants.endPoints.login, formData, true).then(function (response) {
          if (response.data.errors != null) {
            let errors = response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.onCapture();
          } else {
            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));
            this.checkPolicies();
          }
        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          let message = '';
          try {
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
          } catch (e) {
            console.log(e);
            message = e;
          }

          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;

          this.onCapture();
        }.bind(this));

      } else {

        const formData = new FormData();
        formData.append('document_id', this.user.document_id);
        formData.append('document_type', this.user.document_type);
        formData.append('coupon', this.user.coupon);
        formData.append('validate_document_id', this.user.validate_document_id);
        formData.append('face_model', selfieFile);

        api.postFile(constants.endPoints.registerUser, formData, true).then(function (response) {
          if (response.data.errors != null) {
            let errors = response.data.errors;
            let message = '';
            for (var [key, value] of Object.entries(errors)) {
              message = message + value + '<br>';
            }
            this.snackbarMessage = message;
            this.snackbar = true;
            this.user.login = false;
            locals.setLocal('user', JSON.stringify(this.user));

            this.onCapture();
          } else {

            this.user.access_token = response.data.data.token;
            this.user.token_type = response.data.data.token_type;
            this.user.login = true;
            locals.setLocal('user', JSON.stringify(this.user));
            this.checkPolicies();
          }

        }.bind(this)).catch(function (error) {
          let errors = error.response.data.errors;
          let message = '';
          for (const [key, value] of Object.entries(errors)) {
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
          this.onCapture();
        }.bind(this))
      }
    },
    checkPolicies() {
      api.get(constants.endPoints.policies, true)
          .then(response => {
            const policies = response.data.data;
            if (policies.length > 0) {
              this.$router.push('/polizas')
            } else {
              this.dialog = true;
              localStorage.clear();
            }
          })
    },
    createCameraElement() {
      this.isLoading = true;
      const constraints = (window.constraints = {
        audio: false,
        video: true
      });
    },
    restart() {
      this.isPhotoTaken = false;
      console.log(this.$refs.webcam);
      this.$nextTick(() => {
        this.$refs.webcam.start();
      });
    },
  },
  created() {
    this.user = locals.getLocal('user');
    if (!this.user) {
      return this.$router.push('/');
    }
  }
}
</script>

<style scoped lang="scss">


</style>